export const environment = {
  production: true,

  ic_quicko_logo: 'https://cdn.quicko.com/assets/images/logo.svg',
  ic_quicko_word_logo: 'https://uat-accounts.quicko.com/assets/icons/ic_quicko_word_logo.svg',
  ic_google_logo: 'https://uat-accounts.quicko.com/assets/icons/ic_google_logo.svg',

  // product logos
  ic_quicko_logo_circle: 'https://uat-accounts.quicko.com/assets/icons/ic_quicko_logo_circle.svg',
  ic_meet_logo_circle: 'https://uat-accounts.quicko.com/assets/icons/ic_meet_logo_circle.svg',
  ic_dark_quicko_logo_circle: 'https://uat-accounts.quicko.com/assets/icons/ic_dark_quicko_logo_circle.svg',

  // social icons
  ic_twitter: 'https://uat-accounts.quicko.com/assets/icons/ic_twitter.svg',
  ic_linkedin: 'https://uat-accounts.quicko.com/assets/icons/ic_linkedin.svg',
  ic_instagram: 'https://uat-accounts.quicko.com/assets/icons/ic_instagram.svg',

  // endpoints
  auth_services_endpoint: 'https://y34ngcdtdl.execute-api.ap-south-1.amazonaws.com/uat',
  authentication_host: 'https://uat-accounts.quicko.com/',
  reference_data_endpoint: 'https://d1sgfr3whqe5qb.cloudfront.net',

  //urls
  myaccount_quicko_host: 'https://uat-myaccount.quicko.com',
  income_tax_url: "https://uat-it.quicko.com"
  // common icons

  // common images
};